import * as React from 'react';
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import styled from '@emotion/styled';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import SiteWidthWrapper from '../../components/wrappers/siteWidthWrapper';
import HeroImageA from '../../components/MainHero';
import CenteredButton from '../../components/centeredButton';

const FlexTwo = styled.div`
  display: grid;


  @media (min-width: 1000px) {
    grid-template-columns: 2fr 1fr;
  }
`;

const InteriorPaintingServices = () => (
    <Layout>
		<Seo title='Top1 Interior Painting'
         description='Professional interior painting services' />

      {/* Hero Image is found in /components/MainHero.js */}
      <HeroImageA />

		<SiteWidthWrapper>

		<FlexTwo>
			<div>
					<h1>Interior Painting &amp; Renovations</h1>
					<p>Our professional interior painters will come in and before you know it, your home will look the way you want it!! Freshen up a single room or repair and paint the whole house. We handle all jobs, big or small, Our professional staff paints every home as if it were their own. We strive to provide high quality home interior painting service at competitive prices, and while doing so we will insure to maintain total honesty, fairness, and transparency.We understand that Interior painting projects require close attention to details, lasting results and top quality finishings to complement your living and personal preferences to style. Interior projects are about personal elegance and tastes, And we strive to do it all for you personally as we’re completely driven to exceed your expectations and more. At Top1 painting we help you select colours, pick the right kind of paint, and will meet all your needs.</p>
					<p>We believe that in addition to being completely proficient excellent painting results come from the thoroughness of our preparation, especially pre-paint masking of floors and covering furniture, cleaning walls, ceilings and trim, crack filling, sanding, patching and caulking.</p>
					<CenteredButton>CALL NOW</CenteredButton>
			</div>
			<div>
				<h2>Our Interior Painting Services Include:</h2>
				<ul>
					<li>Walls and Ceilings</li>
					<li>Doors and Windows</li>
					<li>French Doors and Windows</li>
					<li>Mantels and Bookshelves</li>
					<li>Paneling and Wainscoting</li>
					<li>Cabinets and Wood Shelves</li>
					<li>Baseboard and Moldings</li>
				</ul>
			</div>
		</FlexTwo>
		</SiteWidthWrapper>

	</Layout>
);

export default InteriorPaintingServices;
